@media only screen and (max-width: 540px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-page .login-right-col {
    padding-top: 0px;
  }
  .login-page .login-right-col .lottie-img {
    text-align: center;
  }
  .login-page .login-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }

  /* registration */
  .register-page .register-left-col {
    display: none;
  }
  .register-page .register-right-col {
    padding-top: 0px;
  }
  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
  .register-page .form-grid {
    grid-template-columns: 1fr;
  }

  .sidebar-links .css-5uknkm-MuiTypography-root {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-page .login-right-col .lottie-img {
    text-align: center;
  }
  .login-page .login-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }
  /* register */

  .register-page .register-left-col {
    display: none;
  }
  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
}
.login-page .login-right-col .lottie-img {
  text-align: center;
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* .login-page .login-left-col {
    display: none;
  } */
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}

@media print {
  .invoice_image {
    display: inline-block !important;
    padding-top: 15px;
  }
  .css-1thxjj-MuiSvgIcon-root {
    height: 0 !important;
    overflow: hidden !important;
    display: none;
  }
  /* @page { size: landscape; } */
  html,
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    height: 100vh; /* Use 100% here to support printing more than a single page*/
  }
  .invoice_print_form {
    /* position: absolute; */
    display: flex;
    justify-content: space-between;
    bottom: 20px;
    height: 100%;
  }
  .invoice_footer_form {
    position: absolute;
    display: flex !important;
    justify-content: space-between;
    bottom: 10px;
    width: 100%;
    padding: 25px;
  }
}

.invoice_image {
  display: none;
}
.invoice-card-header {
  align-items: center;
}
.invoice_print_form {
  position: relative;
}
.invoice_footer_form {
  display: none;
}
.invoice_icon_section {
  padding: 10px;
}
/* @media (min-width: 960px) {
  .css-1t7poco-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

@media (min-width: 1280px) {
  .css-1t7poco-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
  justify-content: center;
} */
@media (min-width: 960px) {
  .css-1flfcxk {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

@media (min-width: 1280px) {
  .css-1flfcxk {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

.css-1d3bbye {
  justify-content: center;
}

/* member from print  */
.member-info-print {
  border: 1px solid gray;
  border-collapse: collapse;
  width: 100%;
}
z .id-card-form {
  border-top: none;
}

.member-info-print tbody tr {
  border: 1px solid gray;
  width: 100%;
}
.member-info-print tbody tr td {
  padding-left: 10px;
  /* width: 100% !important; */
}
.member-info-print tbody tr th {
  padding-left: 8px;
  height: 20px !important;
}
/* .member-info-print tbody tr td {
  height: 20px !important;
} */
.table-head {
  text-align: start;
  border-right: 1px solid gray;
  width: 30%;
}

/* .member-info-print-long-text {
  width: 100% !important;
} */

.table-middle-head {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding-right: 5px;
}
.inbound td {
  width: 50px !important;
}
.table-head-data tr th {
  width: 100% !important;
}

.business-license-table-head th {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  text-align: center;
}
.business-license-table-body td {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  text-align: center;
}

.business-license-signature td {
  height: 100px !important;
}
.document-submit thead tr th {
  border-right: 1px solid gray;
}
.document-submit tbody tr td {
  border-right: 1px solid gray;
  padding-right: 5px;
}
