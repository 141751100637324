/* sidebar */
.sidebar-links .css-5uknkm-MuiTypography-root {
  font-family: "Jost", sans-serif;
  font-size: 1.1rem;
}
.company-info h2 {
  padding-bottom: 10px;
}
.company-info ul {
  margin: 0;
  padding: 0;
}
.company-info ul li {
  display: flex;
  margin: 16px 0;
  align-items: center;
}
.company-info ul li span {
  width: 40%;
  font-weight: 600;
  font-size: 0.9rem;
}
.company-info ul li p {
  padding-left: 10px;
  font-size: 0.9rem;
  margin: 0;
}

.dashboard-main-section .box-container {
  /* border-bottom: 2px solid #1113a2; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px 15px;
  border-radius: 4px;
  word-break: break-all;
}
.payment-info ul {
  margin: 0px;
  padding: 0;
}
.payment-info ul li {
  display: flex;
  margin-bottom: 10px;
}
.payment-info ul li span {
  width: 25%;
  font-weight: 400;
  font-size: 0.8rem;
  display: inline-block;
}
.payment-info ul li p {
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 600;
}

/* representative info */

.box-container {
  /* border-bottom: 2px solid #1113a2; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px 15px;
  border-radius: 4px;
  word-break: break-all;
  background-color: #ffffff;
}

.approved {
  color: #388e3c;
}
.pending {
  color: #f57f17;
  font-weight: bold;
}
.canceled {
  color: #e8efff;
}
@media only screen and (max-width: 896px) {
  .company-info ul li span {
    width: 45%;
    margin-right: 10px;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

.bg-style {
  background: #e8efff;
  padding: 15px;
  border-radius: 10px;
}
.bg-style.yellowshade {
  background: #f3fbe8;
}
.bg-style.grayshade {
  background: #e8efff;
}
.bg-style.greenshade {
  background: #f2f5cb;
}
.bg-style.pinkshade {
  background: #eeeeee;
}
.bg-style.five {
  background: #f3fbe8;
}

.notification-read {
  font-weight: bold;
  color: gray;
  font-size: 13px !important;
}
.notification-unread {
  font-weight: bold;
  color: #0c4da2;
  font-size: 13px !important;
}
.single-trainee-application {
  margin-top: 20px;
}
.single-trainee-application ul {
  padding: 0;
  margin: 0;
}

.single-trainee-application ul li {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
}
.single-trainee-application ul li span {
  width: 20%;
  font-weight: 600;
  font-size: 0.9rem;
}
.single-trainee-application ul li p {
  padding-left: 10px;
  font-size: 0.9rem;
  width: 80%;
  border-bottom: 1px solid gray;
}
.single-trainee-application ul li p a {
  color: #046df7;
}
.form-input {
  width: 46% !important;
  font-weight: 600;
  font-size: 0.9rem;
}

.second-input {
  width: 20% !important;
  font-weight: 600;
  font-size: 0.9rem;
}
.from-long-text-title {
  width: 26% !important;
  font-weight: 600;
  font-size: 0.9rem;
}
