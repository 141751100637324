:root {
  --main-color: #0c4da2;
  --dark-color: #1d2231;
  --text-grey-color: #394049;
  --secondary-color: rgba(34, 51, 84, 0.7);
}

.main-color {
  color: var(--main-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.dark-blue-color {
  color: var(--dark-color);
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 10px;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.ml-0 {
  margin-left: 0px;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 10px;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 3rem;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  padding-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 10px;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.pt-1 {
  margin-top: 0.25rem;
}

.pt-2 {
  margin-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  margin-top: 1.5rem;
}

.pt-5 {
  margin-top: 3rem;
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 3rem;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-5 {
  padding-right: 3rem;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 99999999;
}

.batch-report {
  height: 300px !important;
}

/* PrintStyles.css */
@media print {

  html,
  body {
    counter-reset: page;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: A4;
  }

  .print-page {
    page-break-before: always;
  }

  .print-page::before {
    counter-increment: page;
    content: "Page " counter(page);
    font-size: 10px;
    /* Adjust font size as needed */
    color: #555;
    /* Adjust color as needed */
    position: absolute;
    bottom: 10px;
    left: 50%;
  }

  .print-image {
    width: "100%";
  }
}

.batch-report-select .css-1bwxe7o-MuiList-root-MuiMenu-list {
  height: 250px !important;
}

.trainee-tab .css-d28k6a-MuiTabPanel-root {
  padding: 10px 0px !important;
}

.pdf-wrapper {
  width: 70%;
  background: #f9f9f9;
  margin: auto;
  min-height: 100vh;
  position: relative;
}

.card {
  display: flex;
  /* align-items: center; */
  padding: 10px;
  font-family: Arial, sans-serif;
}

.photo {
  width: 100px;
  height: 120px;
  margin-right: 20px;
}

.info {
  display: flex;
  flex-grow: 1;
}

.column {
  margin-right: 20px;
  width: 300px;
}

.label {
  font-weight: bold;
  font-size: 20px;
}

.value {
  margin-bottom: 5px;
  font-size: 20px;
}

/* ---------------- */


.table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}


.td {
  background-color: #f0f0f0;
  border: 1px solid black;
  text-align: center;
  padding: 8px 5px;
}

.th {
  border: 1px solid black;
  text-align: center;
}